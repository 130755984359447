<template>
    <div>
        <p>请复制以下地址自行下载</p>
        <p>①谷歌浏览器下载链接:&nbsp;&nbsp;&nbsp;&nbsp;<span>http://pic.csmicrobit.club/soft/chrome.exe</span>&nbsp;&nbsp;&nbsp;&nbsp;<a href="http://pic.csmicrobit.club/soft/chrome.exe" download>浏览器下载</a></p>
        <p>②wps免费版下载链接:&nbsp;&nbsp;&nbsp;&nbsp;<span>https://pacakge.cache.wpscdn.cn/wps/download/W.P.S.10228.12012.2019.exe</span>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://pacakge.cache.wpscdn.cn/wps/download/W.P.S.10228.12012.2019.exe" download>wps下载</a></p>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>